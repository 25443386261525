<template>
	<div style="padding:0 12px 20px">
		<div class="wrap">
			<h2 style="margin: 12px 0;">光谱计划用户注册协议</h2>
			<p style="margin: 12px 0;">最新更新时间：2024年3月26日</p>
			<div>
				在使用本系统的所有功能之前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用本系统，但如果您使用本系统，您的使用行为将被视为对本声明全部内容的认可。
			</div>
			<h4>免责声明</h4>
			<div>
				鉴于本系统使用非人工检索/分析方式，无法确定您输入的条件进行是否合法，所以本系统对检索/分析出的结果不承担责任。如果因以本系统的检索/分析结果作为任何商业行为或者学术研究的依据而产生不良后果，本系统不承担任何法律责任。
			</div>
			<h4>关于隐私权</h4>
			<div>
				访问者在本系统注册时提供的一些个人资料，本系统除您本人同意外不会将用户的任何资料以任何方式泄露给第三方。当政府部门、司法机关等依照法定程序要求本系统披露个人资料时，本系统将根据执法单位之要求或为公共安全之目的提供个人资料，在此情况下的披露，本系统不承担任何责任。
			</div>
			<h4>关于版权</h4>
			<div>1.凡本系统的所有作品，其版权属于北京贝湾教育科技有限公司和本系统所有。其他媒体、APP/Web或个人转载使用时不得进行商业性的原版原式的转载，也不得歪曲和篡改本系统所发布的内容。</div>
			<div>2.被本系统授权使用的单位，不应超越授权范围。 </div>
			<div>3.本系统提供的资料如与相关纸质文本不符，以纸质文本为准。</div>
			<div>4.如因作品内容、版权和其它问题需要同本系统联系的，请在本系统发布该作品后的30日内进行。</div>
			<h4>关于解释权</h4>
			<div>本系统之声明以及其修改权、更新权及最终解释权均属本APP/Web以及贝湾所有。
			</div>
			<h4>免责声明</h4>
			<div>
				本系统的各项电子服务的所有权和运作权归北京贝湾教育科技有限公司（以下简称“贝湾”）。系统提供的服务将完全按照其发布的章程、服务条款和操作规则严格执行。用户若完全同意所有服务条款，并完成注册程序，即可成为光谱计划APP/Web的正式用户。基于APP/Web提供的网络服务的商业性和时效性：
			</div>
			<div> 1.用户对其发布的信息负责，不得发布不真实的、有歧义的信息，绝对禁止发布误导性的、恶意的消息。 </div>

			<div>2.随时更新注册资料，以符合及时、详尽、准确的要求。</div>
			<div>3.光谱计划APP/Web向用户提供的帐号及密码只供用户本人使用。如果用户将帐号或密码丢失或被盗，应及时重新登记并重新设置密码。用户造成的帐号失密，应自行承担责任。</div>
			<div>4.用户发现APP/Web发布的信息不准确或发生错误，以及其它错误行为，应及时通知本网。若问题未能及时解决，可进入本网的投诉程序。</div>
			<div>5.光谱计划APP/Web向用户提供的商品信息之编辑、编制、传播的版权和知识产权，全部由贝湾拥有；未经贝湾事先以书面表示同意，任何人不得以各种形式复制、散布、出售、出版、广播、转播该商品信息。</div>

			<div>6.光谱计划APP/Web如发现用户提供的资料包含有不正确或者违法的信息，将发出警告，并保留停止或终止其用户资格的权利。</div>
			<div>7.用户如违反本协议而造成贝湾的经济损失，本网有权追究其经济责任与法律责任。</div>
			<h4>用户权利与义务</h4>

			<div>1.用户承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线；</div>
			<div>2.用户在其名称、头像和简介等注册信息中不得出现违法和不良信息；</div>
			<div>
				3.光谱计划APP/Web将配备与服务规模相适应的专业人员，对用户提交的账号名称、头像和简介等注册信息进行审核，对含有违法和不良信息的，不予注册，对用户发布的违法信息，有权进行删除处理，多次出现违法行为的账号，光谱计划APP/Web有权删除账号，并不退还相关款项。
			</div>

			<div>4.光谱计划APP/Web保护用户信息及公民个人隐私，自觉接受社会监督，及时处理公众举报的账号名称、头像和简介等注册信息中的违法和不良信息。</div>
			<div>5.用户的所有行为必须符合国家法律的规定，如违反国家法律规定和本协议，出现任何纠纷及不良结果，将由行为人独立承担所有责任。</div>
			<div>6.本APP/Web上的信息未经合法权利人的书面许可，任何人严禁在本APP/Web上展示产品图片。任何未经授权便在本APP上使用该图片都可能违反国际法，商标法，隐私权法，通讯、通信等法律法规。</div>
			<div>
				7.浏览者可以截图光谱计划APP/Web显示的资料，但这些资料只限用于个人学习研究使用，不得用于任何商业用途，无论是否在资料上明示，所有此等资料都是受到版权法的法律保护。浏览者没有获得光谱计划APP/Web或各自的版权所有者明确的书面同意下，不得分发、修改、散布、再使用、再传递或使用本APP/Web的内容用于任何公众商业用途。
			</div>
		</div>
	</div>
</template>

<script >
export default {
  data: function () {
    return {
    }
  },
  methods: {
    init () {}
  },
  created () { this.init() },
  mounted () {},
  beforeDestroy () {}
}
</script>
<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
    font-family: '微软雅黑';
    color: rgb(0, 0, 0)
  }
  h2 {
    font-size: 1.5em;
    font-family: '宋体';
  }
  h4 {
    margin-top: 5.0000pt;
    margin-bottom: 5.0000pt;
    font-size: 13.5000pt;
    font-family: '宋体';
  }
  div {
    margin-top: 5.0000pt;
    margin-bottom: 5.0000pt;
    font-size: 12.0000pt;
    font-family: Calibri;
    word-break: break-all;
  }
  .Bold {
    font-weight: 600;
  }
</style>
